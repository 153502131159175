/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule, DatePipe} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SignInComponent} from './components/sign-in/sign-in.component';
import {SignUpComponent} from './components/sign-up/sign-up.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {VerifyEmailComponent} from './components/verify-email/verify-email.component';
import {environment} from '../environments/environment';
import {AuthService} from './shared/services/auth.service';
import {ButtonModule} from 'primeng/button';
import {ConfirmationService, MessageService} from 'primeng/api';
import {PlaceModule} from './components/place/place.module';
import {ToastModule} from 'primeng/toast';
import {AngularFireStorageModule} from '@angular/fire/compat/storage';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireModule} from '@angular/fire/compat';
import {LoggerModule, NgxLoggerLevel} from 'ngx-logger';
import {MapModule} from "./components/map/map.module";
import {ListModule} from "./components/list/list.module";
import {NotFoundComponent} from "./components/not-found/not-found.component";
import {BaseMapListComponent} from './components/base-map-list/base-map-list.component';
import {FaqComponent} from './components/faq/faq.component';
import {FirebaseService} from "./services/firebase.service";
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { BooleanToYesNoPipe } from './shared/widgets/boolean-to-yes-no.pipe';
import {InputTextModule} from "primeng/inputtext";
import {MultiSelectModule} from "primeng/multiselect";
import {ProgressBarModule} from "primeng/progressbar";
import {SkeletonModule} from "primeng/skeleton";
import {TableModule} from "primeng/table";
import {ToolbarModule} from "primeng/toolbar";
import {TooltipModule} from "primeng/tooltip";
import {RippleModule} from "primeng/ripple";
import {ImageModule} from "primeng/image";
import { AdListComponent } from './components/ads-list/ad-list.component';
import {CalendarModule} from "primeng/calendar";
import { AdEditComponent } from './components/ads-list/ad-edit/ad-edit.component';
import {DialogModule} from "primeng/dialog";
import {MessagesModule} from "primeng/messages";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {TableUtilsModule} from "./components/table-utils/table-utils.module";
import {HttpClientModule} from "@angular/common/http";

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    VerifyEmailComponent,
    NotFoundComponent,
    BaseMapListComponent,
    FaqComponent,
    UserProfileComponent,
    BooleanToYesNoPipe,
    AdListComponent,
    AdEditComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    ReactiveFormsModule,
    PlaceModule,
    CommonModule,
    ButtonModule,
    ToastModule,
    LoggerModule.forRoot({
      serverLoggingUrl: 'http://localhost:8080',
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.OFF,
    }),
    MapModule,
    ListModule,
    InputTextModule,
    MultiSelectModule,
    ProgressBarModule,
    SkeletonModule,
    TableModule,
    ToolbarModule,
    TooltipModule,
    FormsModule,
    RippleModule,
    ImageModule,
    CalendarModule,
    DialogModule,
    MessagesModule,
    ConfirmDialogModule,
    TableUtilsModule,
    HttpClientModule,
  ],
  providers: [
    AuthService,
    ConfirmationService,
    DatePipe,
    FirebaseService,
    MessageService,
  ],
  bootstrap: [AppComponent],
  exports: [
  ]
})
export class AppModule {
}

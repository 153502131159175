/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

export interface Tagline {
  label: string,
  value: string,
  tagline: string,
  mapUrl: string,
}

export const statesDD: Tagline[] = [
  { label: 'Alabama', value: 'AL', tagline: '', mapUrl: '' },
  { label: 'Alaska', value: 'AK', tagline: '', mapUrl: '' },
  { label: 'Arizona', value: 'AZ', tagline: '', mapUrl: '' },
  { label: 'Arkansas', value: 'AR', tagline: '', mapUrl: '' },
  { label: 'California', value: 'CA', tagline: 'Sip the Spirit of the Golden State', mapUrl: '' },
  { label: 'Colorado', value: 'CO', tagline: 'From Our Peaks to Your Pints', mapUrl: '' },
  { label: 'Connecticut', value: 'CT', tagline: '', mapUrl: '' },
  { label: 'Delaware', value: 'DE', tagline: '', mapUrl: '' },
  { label: 'Florida', value: 'FL', tagline: 'Brewed Under the Sunshine State', mapUrl: '' },
  { label: 'Georgia', value: 'GA', tagline: 'Crafting Beers with a Peanut State of Mind', mapUrl: '' },
  { label: 'Hawaii', value: 'HI', tagline: '', mapUrl: '' },
  { label: 'Idaho', value: 'ID', tagline: '', mapUrl: '' },
  { label: 'Illinois', value: 'IL', tagline: 'Crafted in the Land of Lincoln', mapUrl: '' },
  { label: 'Indiana', value: 'IN', tagline: 'Savor the Flavor of Hoosier Hospitality', mapUrl: '' },
  { label: 'Iowa', value: 'IA', tagline: 'Hawkeye State Brews: Where Tradition Meets Taste', mapUrl: '' },
  { label: 'Kansas', value: 'KS', tagline: '', mapUrl: '' },
  { label: 'Kentucky', value: 'KY', tagline: ' Blaze the Bluegrass TagaBrew Trail to all our landmark Breweries ', mapUrl: '' },
  { label: 'Louisiana', value: 'LA', tagline: '', mapUrl: '' },
  { label: 'Maine', value: 'ME', tagline: '', mapUrl: '' },
  { label: 'Maryland', value: 'MD', tagline: '', mapUrl: '' },
  { label: 'Massachusetts', value: 'MA', tagline: '', mapUrl: '' },
  { label: 'Michigan', value: 'MI', tagline: 'Mitten Made, Michigan Proud', mapUrl: 'https://www.google.com/maps/d/embed?mid=1D6Li7xq7Kr7UZvONNeDs5Y4mzOPKST8v&ehbc=2E312F' },
  { label: 'Minnesota', value: 'MN', tagline: 'From the Land of 10,000 Lakes, Cheers!', mapUrl: 'https://www.google.com/maps/d/embed?mid=1mlATchu-0fHjOKvWazwCAQGZNDWbu7_6&ehbc=2E312F' },
  { label: 'Mississippi', value: 'MS', tagline: 'Savor the Flavors of the Magnolia State', mapUrl: 'https://www.google.com/maps/d/embed?mid=1xziWBStBaB2Rr9evnrZuGigjwNsmU3M&ehbc=2E312F' },
  { label: 'Missouri', value: 'MO', tagline: '', mapUrl: '' },
  { label: 'Montana', value: 'MT', tagline: '', mapUrl: '' },
  { label: 'Nebraska', value: 'NE', tagline: '', mapUrl: '' },
  { label: 'Nevada', value: 'NV', tagline: 'Neon Lights, High Stakes, and Great Brews', mapUrl: '' },
  { label: 'New Hampshire', value: 'NH', tagline: '', mapUrl: '' },
  { label: 'New Jersey', value: 'NJ', tagline: 'Where Garden State Roots Grow into Great Brews', mapUrl: '' },
  { label: 'New Mexico', value: 'NM', tagline: '', mapUrl: '' },
  { label: 'New York', value: 'NY', tagline: '', mapUrl: '' },
  { label: 'North Carolina', value: 'NC', tagline: 'Mountain Fresh Brews from the Blue Ridge', mapUrl: '' },
  { label: 'North Dakota', value: 'ND', tagline: '', mapUrl: '' },
  { label: 'Ohio', value: 'OH', tagline: 'Unleash the Buck Eye Brew Spirit', mapUrl: '' },
  { label: 'Oklahoma', value: 'OK', tagline: '', mapUrl: '' },
  { label: 'Oregon', value: 'OR', tagline: '', mapUrl: '' },
  { label: 'Pennsylvania', value: 'PA', tagline: 'Experience Keystone State Flavor', mapUrl: '' },
  { label: 'Rhode Island', value: 'RI', tagline: '', mapUrl: '' },
  { label: 'South Carolina', value: 'SC', tagline: 'Pouring Myrtle Beach Sunshine in Every Glass', mapUrl: '' },
  { label: 'South Dakota', value: 'SD', tagline: '', mapUrl: '' },
  { label: 'Tennessee', value: 'TN', tagline: 'Pouring the Best of Music City', mapUrl: '' },
  { label: 'Texas', value: 'TX', tagline: '', mapUrl: '' },
  { label: 'Utah', value: 'UT', tagline: '', mapUrl: '' },
  { label: 'Vermont', value: 'VT', tagline: '', mapUrl: '' },
  { label: 'Virginia', value: 'VA', tagline: 'Virginia’s Craft: Bold and Timeless', mapUrl: '' },
  { label: 'Washington', value: 'WA', tagline: '', mapUrl: '' },
  { label: 'West Virginia', value: 'WV', tagline: '', mapUrl: '' },
  { label: 'Wisconsin', value: 'WI', tagline: 'Wisconsin Brews: Perfect with Cheese', mapUrl: '' },
  { label: 'Wyoming', value: 'WY', tagline: '', mapUrl: '' },
  { label: 'U.S. Virgin Islands', value: 'VI', tagline: 'Enjoy some Island time on the U.S. Virgin Islands TagaBrew Trail.<br>"Caribbean Crafted, Island Approved"', mapUrl: 'https://www.google.com/maps/d/embed?mid=19zqsp9by9kv6T6VgQNNTDcy2RqTEtEs&ehbc=2E312F' },
  { label: 'USA', value: 'US', tagline: '', mapUrl: '' },

]

<!--
  ~ Copyright © MEFTEK LLC. All rights reserved.
  ~ This information is confidential and proprietary to MEFTEK LLC
  ~ and may not be used, modified, copied or distributed.
  -->

<p-toast key="bc" position="bottom-center"></p-toast>

<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

  <ng-container *ngIf="initialized && !canView">
    <h1 class="blink_me" style="color: red;">You must be authorized by the site admin to use this site.</h1>
    <h3 *ngIf="authService.userData as user">You are logged in with email: {{ user.email }}<br><br>Contact the site
      admin.</h3>
    <div class="flex justify-content-center text-sm mt-2 sm:mt-0">
      <a (click)="authService.SignOut()" class="nav-link underline">
        <i class="pi pi-sign-out"></i>&nbsp;Sign out
      </a>
    </div>
  </ng-container>

<p-blockUI [blocked]="blockedUI"></p-blockUI>

  <ng-container *ngIf="canView">
    <p-table
      #placesTable
      (onFilter)="handleFilter($event)"
      (onSort)="handleSort($event)"
      [columns]="selectedColumns"
      [filters]="filters"
      [globalFilterFields]="[
      'name',
      'status',
      'state',
      'city',
      'address',
      'charmDesc'
    ]"
      [loading]="loading"
      [paginator]="true"
      [reorderableColumns]="true"
      [resizableColumns]="true"
      [responsive]="true"
      [rowHover]="true"
      [rowsPerPageOptions]="[100, 250, 500, { showAll: 'All' }]"
      [rows]="100"
      [scrollHeight]="'flex'"
      [scrollable]="true"
      [showCurrentPageReport]="true"
      [showLoader]="false"
      [sortField]="sortField"
      [sortOrder]="sortOrder"
      [value]="places"
      breakpoint="680px"
      columnResizeMode="expand"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
      dataKey="id"
      loadingIcon="pi pi-spin pi-spinner loading-icon-size"
      responsiveLayout="stack"
      scrollDirection="both"
      sortMode="single"
      styleClass="p-datatable-sm p-datatable-gridlines tb-table px-2 pb-2"
    >
      <!-- CAPTION -->
      <ng-template pTemplate="caption">
        <div *ngIf="progress >= 0" [@inOutAnimation]="true" class="p-3">
          <p-progressBar [value]="progress"></p-progressBar>
        </div>
        <p-toolbar>
          <div class="p-toolbar-group-left">
            <p-image
              *ngIf="!isMobile"
              alt="Tagabrew Logo"
              class="mr-2"
              height="40"
              src="assets/tagabrew.png"
            ></p-image>
            <p-button
              (onClick)="handleClearFilter(placesTable); gf.value = ''"
              [disabled]="!isFiltered"
              [icon]="isFiltered ? 'pi pi-filter-slash' : 'pi pi-filter'"
              [ngStyle]="{ cursor: isFiltered ? 'pointer' : 'not-allowed' }"
              [pTooltip]="isFiltered ? 'Clear Filters' : ''"
              styleClass="p-button-info mr-2"
              tooltipPosition="right"
            ></p-button>
            <span class="p-input-icon-left w-full mr-2">
            <i
              class="pi pi-search"
              pTooltip="Filter Table ..."
              tooltipPosition="right"
            ></i>
            <input
              #gf
              (input)="globalFilter(placesTable, $event)"
              [disabled]="false"
              class="w-inherit"
              pInputText
              placeholder="Search keyword"
              type="text"
            />
          </span>
            <p-multiSelect [(ngModel)]="selectedColumns"
                           [disabled]="false"
                           [options]="columns"
                           [style]="{minWidth: '200px'}"
                           optionLabel="header"
                           placeholder="Choose Columns"
                           selectedItemsLabel="{0} columns selected"
            ></p-multiSelect>
          </div>
          <div class="p-toolbar-group-right">
            <p-button (onClick)="handleFirestoreAddPlaces()"
                      icon="pi pi-exclamation-triangle"
                      label="Danger"
                      styleClass="p-button-danger"
                      [loading]="blockedUI"
            ></p-button>
            <p-button
              [disabled]="true"
              [ngStyle]="{ cursor: 'not-allowed' }"
              icon="pi pi-building"
              styleClass="ml-2"
            ></p-button>
            <p-button
              (onClick)="handleUsers()"
              [disabled]="false"
              [ngStyle]="{ cursor: 'pointer' }"
              [pTooltip]="'Show User Profiles'"
              icon="pi pi-users"
              styleClass="ml-2"
              tooltipPosition="left"
            ></p-button>
            <p-button
              (onClick)="handleAds()"
              [disabled]="false"
              [ngStyle]="{ cursor:'pointer' }"
              [pTooltip]="'Show Ads'"
              icon="pi pi-chart-bar"
              styleClass="p-button-secondary ml-2"
              tooltipPosition="left"
            ></p-button>
            <p-button
              (onClick)="handleMap()"
              [disabled]="false"
              [ngStyle]="{ cursor: canView ? 'pointer' : 'not-allowed' }"
              [pTooltip]="canView ? 'Show Map' : ''"
              icon="pi pi-map"
              styleClass="p-button-success ml-2"
              tooltipPosition="left"
            ></p-button>
            <p-button
              (onClick)="handleAdd()"
              [disabled]="false"
              [ngStyle]="{ cursor: 'pointer' }"
              [pTooltip]="'Add New TagaBrew'"
              icon="pi pi-plus"
              styleClass="p-button-info ml-2"
              tooltipPosition="left"
            ></p-button>
            <p-button
              (onClick)="handleResetStaged()"
              [disabled]="resetStagedDisabled"
              [ngStyle]="{ cursor: resetStagedDisabled ? 'not-allowed' : 'pointer' }"
              [pTooltip]="resetStagedDisabled ? '' : 'Reset Staged Edits'"
              icon="pi pi-undo"
              styleClass="p-button-warning ml-2"
              tooltipPosition="left"
            ></p-button>
            <p-button
              (onClick)="handleSaveProd()"
              [badge]="stagedBadge"
              [disabled]="saveDisabled"
              [ngStyle]="{ cursor: saveDisabled ? 'not-allowed' : 'pointer' }"
              [pTooltip]="saveDisabled ? '' : 'Save Staged Edits'"
              badgeClass="p-badge-danger"
              icon="pi pi-save"
              styleClass="p-button-success ml-2 w-4rem"
              tooltipPosition="left"
            ></p-button>
            <p-button
              (onClick)="handleCsvExport()"
              [disabled]="false"
              [pTooltip]="'Download Map List'"
              icon="pi pi-download"
              styleClass="p-button-help ml-2"
              tooltipPosition="left">
            </p-button>
            <p-button
              (onClick)="authService.SignOut()"
              [disabled]="false"
              [pTooltip]="'Sign-Out'"
              icon="pi pi-sign-out"
              styleClass="p-button-danger ml-2"
              tooltipPosition="left">
            </p-button>
          </div>
        </p-toolbar>
      </ng-template>

      <!-- HEADER -->
      <ng-template let-columns pTemplate="header">
        <tr>
          <th
            *ngFor="let col of columns"
            [pSortableColumn]="col.field"
            [style.width]="col.width"
            pReorderableColumn
            pResizableColumn
          >
            <div [ngSwitch]="true" class="p-d-flex p-jc-between p-ai-center">
              {{ col.header }}
              <p-sortIcon [field]="col.field"></p-sortIcon>
              <!-- id, sequence, logoVersion - numeric -->
              <p-columnFilter
                *ngSwitchCase="col.field === 'id' || col.field === 'sequence' || col.field === 'logoVersion'"
                [field]="col.field"
                display="menu"
                type="numeric"
              >
              </p-columnFilter>
              <!-- status, cheerStatus, charmStatus - in (all status') -->
              <p-columnFilter
                *ngSwitchCase="col.field === 'tagStatus' || col.field === 'cheerStatus' || col.field === 'charmStatus'"
                [field]="col.field"
                [showAddButton]="false"
                [showMatchModes]="false"
                [showOperator]="false"
                display="menu"
                matchMode="in"
              >
                <ng-template pTemplate="header">
                  <div class="mt-2 ml-2 font-bold">{{ col.header }} Filter</div>
                </ng-template>
                <ng-template
                  let-filter="filterCallback"
                  let-value
                  pTemplate="filter"
                >
                  <p-multiSelect
                    (onChange)="filter($event.value)"
                    [ngModel]="value"
                    [options]="statusDD"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="Status..."
                  >
                    <ng-template let-option pTemplate="item">
                      <div class="p-multiselect-representative-option">
                        <span class="p-ml-1">{{ option.label }}</span>
                      </div>
                    </ng-template>
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
              <!-- state in -->
              <p-columnFilter
                *ngSwitchCase="col.field === 'state'"
                [field]="col.field"
                [showAddButton]="false"
                [showMatchModes]="false"
                [showOperator]="false"
                display="menu"
                matchMode="in"
              >
                <ng-template pTemplate="header">
                  <div class="mt-2 ml-2 font-bold">State Filter</div>
                </ng-template>
                <ng-template
                  let-filter="filterCallback"
                  let-value
                  pTemplate="filter"
                >
                  <p-multiSelect
                    (onChange)="filter($event.value)"
                    [maxSelectedLabels]="2"
                    [ngModel]="value"
                    [options]="statesDD"
                    [selectedItemsLabel]="'{0} states selected'"
                    optionLabel="label"
                    optionValue="value"
                    placeholder="States..."
                  >
                    <ng-template let-option pTemplate="item">
                      <div class="p-multiselect-representative-option">
                        <span class="p-ml-1">{{ option.label }}</span>
                      </div>
                    </ng-template>
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
              <!-- charm -->
              <p-columnFilter
                *ngSwitchCase="col.field === 'charm'"
                [field]="col.field"
                [showAddButton]="false"
                [showMatchModes]="false"
                [showOperator]="false"
                display="menu"
                matchMode="in"
              >
                <ng-template pTemplate="header">
                  <div class="mt-2 ml-2 font-bold">Charm Filter</div>
                </ng-template>
                <ng-template
                  let-filter="filterCallback"
                  let-value
                  pTemplate="filter"
                >
                  <p-multiSelect
                    (onChange)="filter($event.value)"
                    [ngModel]="value"
                    [options]="charmStatusDD"
                    placeholder="Charm..."
                  >
                    <ng-template let-option pTemplate="item">
                      <span>{{ option.label }}</span>
                    </ng-template>
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
              <!-- cheer/dog/recycle -->
              <p-columnFilter
                *ngSwitchCase="col.field === 'cheer' || col.field === 'dog' || col.field === 'recycle'"
                [field]="col.field"
                [showAddButton]="false"
                [showMatchModes]="false"
                [showOperator]="false"
                display="menu"
                matchMode="in"
              >
                <ng-template pTemplate="header">
                  <div class="mt-2 ml-2 font-bold">{{ pascalCase(col.field) }} Filter</div>
                </ng-template>
                <ng-template
                  let-filter="filterCallback"
                  let-value
                  pTemplate="filter"
                >
                  <p-multiSelect
                    (onChange)="filter($event.value)"
                    [ngModel]="value"
                    [options]="yesNoDD"
                    [placeholder]="pascalCase(col.field) + '...'"
                    optionLabel="label"
                    optionValue="value"
                  >
                    <ng-template let-option pTemplate="item">
                      <span>{{ option.label }}</span>
                    </ng-template>
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
              <!-- rqc -->
              <p-columnFilter
                *ngSwitchCase="col.field === 'rqc'"
                [field]="col.field"
                [showAddButton]="false"
                [showMatchModes]="false"
                [showOperator]="false"
                display="menu"
                matchMode="in"
              >
                <ng-template pTemplate="header">
                  <div class="mt-2 ml-2 font-bold">Why Retired Filter</div>
                </ng-template>
                <ng-template
                  let-filter="filterCallback"
                  let-value
                  pTemplate="filter"
                >
                  <p-multiSelect
                    (onChange)="filter($event.value)"
                    [ngModel]="value"
                    [options]="rqcDD"
                    [placeholder]="pascalCase(col.field) + '...'"
                    optionLabel="label"
                    optionValue="value"
                  >
                    <ng-template let-option pTemplate="item">
                      <span>{{ option.label }}</span>
                    </ng-template>
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
              <!-- dirty/staged -->
              <p-columnFilter
                *ngSwitchCase="col.field === 'dirty' || col.field === 'staged'"
                [field]="col.field"
                [showAddButton]="false"
                [showMatchModes]="false"
                [showOperator]="false"
                display="menu"
                matchMode="in"
              >
                <ng-template pTemplate="header">
                  <div class="mt-2 ml-2 font-bold">{{ pascalCase(col.field) }} Filter</div>
                </ng-template>
                <ng-template
                  let-filter="filterCallback"
                  let-value
                  pTemplate="filter"
                >
                  <p-multiSelect
                    (onChange)="filter($event.value)"
                    [ngModel]="value"
                    [options]="yesNoTfDD"
                    [placeholder]="pascalCase(col.field) + '...'"
                    optionLabel="label"
                    optionValue="value"
                  >
                    <ng-template let-option pTemplate="item">
                      <span>{{ option.label }}</span>
                    </ng-template>
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
              <!-- createdDate, updatedDate - Date -->
              <p-columnFilter
                *ngSwitchCase="col.field === 'createdDateValue' || col.field === 'updatedDateValue'"
                [field]="col.field"
                display="menu"
                type="date"
              >
              </p-columnFilter>
              <!-- default/text -->
              <p-columnFilter
                *ngSwitchDefault
                [field]="col.field"
                display="menu"
                matchMode="contains"
                type="text"
              >
              </p-columnFilter>
            </div>
          </th>
          <th
            [frozen]="true"
            alignFrozen="right"
            class="w-9rem"
            pFrozenColumn
          >
            action
          </th>
        </tr>

        <!-- LOADING -->
        <tr *ngIf="loading">
          <td class="w-screen" colspan="22">
            <p-progressBar
              [style]="{ height: '0.4rem' }"
              mode="indeterminate"
            ></p-progressBar>
          </td>
        </tr>
      </ng-template>

      <!-- LOADING BODY -->
      <ng-template pTemplate="loadingbody">
        <ng-container *ngIf="loading && loadingBody && !places?.length">
          <tr *ngFor="let _ of [1, 2, 3, 4, 5]" class="loading-height">
            <td
              *ngFor="let column of columns"
              [ngStyle]="{ flex: '0 0 ' + column.width }"
            >
              <div class="loading-flex">
                <p-skeleton class="w-full"></p-skeleton>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-template>

      <!-- BODY -->
      <ng-template let-columns="columns" let-place pTemplate="body">
        <tr>
          <td
            *ngFor="let col of columns"
            [ngClass]="styleRow(place)"
            [style.width]="col.width"
          >
            <span class="p-column-title">{{ col.header }}</span>
            <ng-container [ngSwitch]="true">
              <!-- urls -->
              <ng-container
                *ngSwitchCase="col.field === 'websiteURL'
                          || col.field === 'facebookURL'
                          || col.field === 'instagramURL'
                          || col.field === 'twitterURL'
                          || col.field === 'untappd'"
              >
                <a href="{{ place[col.field] }}" target="_blank">{{ place[col.field] }}</a>
              </ng-container>
              <!-- dropdowns -->
              <ng-container *ngSwitchCase="col.field === 'tagStatus' || col.field === 'cheerStatus' ||
                  col.field === 'rqc' || col.field === 'charm' || col.field === 'cheer' ||
                  col.field === 'dog' || col.field === 'recycle' ||
                  col.field === 'dirty' || col.field === 'staged'">
                {{ statusName(place[col.field]) }}
              </ng-container>
              <!-- phone -->
              <ng-container *ngSwitchCase="col.field === 'phone'">
                {{ place[col.field] | phoneNumber }}
              </ng-container>
              <!-- createdDate, updatedDate -->
              <ng-container *ngSwitchCase="col.field === 'createdDateValue' || col.field === 'updatedDateValue'">
                {{ place[col.field] | date : 'yyyy-MM-dd HH:mm:ss' }}
              </ng-container>
              <!-- default/string -->
              <ng-container *ngSwitchDefault>
                {{ place[col.field] }}
              </ng-container>
            </ng-container>
          </td>
          <td
            [frozen]="true"
            [ngClass]="styleRow(place)"
            alignFrozen="right"
            class="w-49rem justify-content-center"
            pFrozenColumn
          >
            <button
              (click)="handleEdit(place)"
              [disabled]="false"
              [ngStyle]="{ cursor: 'pointer' }"
              class="p-button-info p-button-rounded"
              icon="pi pi-pencil"
              pButton
              pTooltip="Edit Place ..."
              tooltipPosition="left"
              type="button"
            ></button>
            <button
              (click)="handleCancelEdit(place)"
              [disabled]="cancelEditDisabled(place['staged'])"
              [ngStyle]="{cursor: cancelEditDisabled(place['staged']) ? 'not-allowed' : 'pointer'}"
              [pTooltip]="cancelEditDisabled(place['staged']) ? '' : 'Cancel Edit ...'"
              class="p-button-warning p-button-rounded"
              icon="pi pi-undo"
              pButton
              tooltipPosition="left"
              type="button"
            ></button>
            <button
              (click)="handleShowMap(place)"
              [disabled]="false"
              [ngStyle]="{ cursor:'pointer' }"
              [pTooltip]="'Show Map ...'"
              class="p-button-success p-button-rounded"
              icon="pi pi-map"
              pButton
              pTooltip="Show Map ..."
              tooltipPosition="left"
              type="button"
            ></button>
          </td>
        </tr>
      </ng-template>

      <!-- SUMMARY -->
      <ng-template pTemplate="summary">
        <app-table-summary></app-table-summary>
      </ng-template>
    </p-table>
  </ng-container>

<app-update-place
  (submitEvent)="handleSubmit($event)"
  *ngIf="displayUpdateForm"
  [(visible)]="displayUpdateForm"
  [place]="currentPlace"
  [places]="places"
  [updateType]="updateType"
>
</app-update-place>

<app-show-map
  *ngIf="displayMap"
  [(visible)]="displayMap"
  [place]="currentPlace"
  [zoom]="defaultZoom"
>
</app-show-map>

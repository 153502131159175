/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {Component, OnInit} from '@angular/core';
import {Place} from "../../models/place.model";
import {FirebaseService} from "../../services/firebase.service";
import {MessageService} from "primeng/api";
import {NGXLogger} from "ngx-logger";
import {ActivatedRoute, Router} from "@angular/router";
import {getStateGps, getStateName, getStatusDescription} from "../place/place.conf";
import {BaseMapListComponent} from "../base-map-list/base-map-list.component";

declare var google: any;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent extends BaseMapListComponent implements OnInit {
  public options: any = {};
  public overlays: any[] = [];
  public map: any; // google.maps.Map;
  public showGoBack = false;
  public zoom = 5;
  public latitude: number;
  public longitude: number;
  public gps: any;
  public openInfoWindow: any;

  constructor(
    protected readonly firebaseService: FirebaseService,
    protected readonly messageService: MessageService,
    protected readonly logger: NGXLogger,
    protected route: ActivatedRoute,
    protected router: Router,
  ) {
    super(firebaseService, messageService, logger, route);
  }

  ngOnInit(): void {
    const stateParam = this.route.snapshot.params['state'].toUpperCase();
    this.state = getStateName(stateParam);
    if (this.isValidState) {
      this.gps = getStateGps(this.state);
      this.latitude = this.gps.lat;
      this.longitude = this.gps.long;
      this.zoom = this.state === 'USA' ? 4.5 : 6;

      this.getPlacesProd(this.state);
    } else {
      this.router.navigate(['/404']).then();
    }
  }

  onMapReady($event: any): void {
    this.map = $event.map;

    this.map.setCenter({
      lat: this.latitude,
      lng: this.longitude,
    });

    this.map.setZoom(this.zoom);

    this.map.addListener("click", () => {
      // Close the current InfoWindow.
      this.openInfoWindow?.close();
    });

    this.showMap();
  }

  /**
   * This method shows the map
   * @private
   */
  private showMap() {
    if (this.map) {
      this.overlays = [];
      this.places.forEach((place) => {
        if (this.showBrewery(place)) {
          const lat = Number(place.latitude).toFixed(6);
          const lng = Number(place.longitude).toFixed(6);
          const title = `${place.name} (${getStatusDescription(place.tagStatus)})`;
          let fillColor = '#4285F4';
          let labelColor = 'white';
          if (place.tagStatus === 'S') {
            fillColor = '#FBBC05';
            labelColor = 'black';
          } else if (place.tagStatus === 'R') {
            fillColor = '#FF00FF';
            labelColor = 'black';
          } else if (place.tagStatus === 'H') {
            fillColor = '#a5a5a5';
            labelColor = 'black';
          }
          let contentString =
            `<div>
              <span class="font-bold text-lg">${place.name}</span>
              <span>(${getStatusDescription(place.tagStatus)})</span>
            </div>
            <div class="text-sm">${place.address}</div>`;
          if (this.showGoBack) {
             contentString += `<div class = "text-sm" >${lat},${lng}</div>`;
          }
          const infoWindow = new google.maps.InfoWindow({content: contentString});
          const svgMarker = {
            path: 'M0-48c-9.8 0-17.7 7.8-17.7 17.4 0 15.5 17.7 30.6 17.7 30.6s17.7-15.4 17.7-30.6c0-9.6-7.9-17.4-17.7-17.4z',
            fillColor: fillColor,
            fillOpacity: 1,
            strokeWeight: 1,
            rotation: 0,
            scale: 0.5, //2,
            anchor: new google.maps.Point(0, 0),
            labelOrigin: new google.maps.Point(0, -28),
          };
          const marker = new google.maps.Marker({
            position: {
              lat: place.latitude,
              lng: place.longitude,
            },
            nap: this.map,
            title: title,
            icon: svgMarker,
            // icon: {
            //   url: "http://maps.google.com/mapfiles/ms/icons/blue.png"
            // },
            label: {
              text: `${place.tagStatus![0]}`,
              color: labelColor,
              fontWeight: "bold",
            }
            // labelOrigin: new google.maps.Point(-9, -9)
          });

          this.overlays.push(marker);

          marker.addListener("click", () => {
            this.openInfoWindow?.close();
            this.openInfoWindow = infoWindow;
            this.openInfoWindow.open({
              anchor: marker,
              map: this.map,
              shouldFocus: false,
            });
          });
        }
      });
    }
  }

  showBrewery(place: Place): boolean {
    if (this.showGoBack) {
      return true;
    }
    return place.tagStatus !== 'H' && place.tagStatus !== 'R';
  }
}

/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {SelectItem} from "primeng/api";

export const defaultSortField = 'displayName';
export const defaultSortOrder = 1;

export const columnDefs = [
  { field: 'uid', header: 'uid', width: '20rem' },
  { field: 'displayName', header: 'Name', width: '15rem' },
  { field: 'email', header: 'Email', width: '15rem' },
  { field: 'ageGroup', header: 'ageGroup', width: '9rem' },
  { field: 'zipCode', header: 'zipCode', width: '9rem' },
  { field: 'emailVerified', header: 'emailVerified', width: '11rem' },
  { field: 'createdOn', header: 'createdOn', width: '11rem' },
  { field: 'lastUsedOn', header: 'lastUsedOn', width: '11rem' },
  { field: 'android', header: 'Android', width: '8rem' },
  { field: 'iOS', header: 'iOS', width: '7rem' },
  { field: 'web', header: 'Web', width: '7rem' },
  { field: 'adsClicked', header: 'Ads Clicked', width: '11rem' },
  { field: 'adsDisplayed', header: 'Ads Displayed', width: '11rem' },
  { field: 'currentVersion', header: 'App Version', width: '13rem' },
];

export const mobileColumnDefs = [
  { field: 'uid', header: 'uid', width: '20rem' },
  { field: 'displayName', header: 'Name', width: '15rem' },
  { field: 'email', header: 'Email', width: '15rem' },
  { field: 'lastUsedOn', header: 'lastUsedOn', width: '11rem' },
  { field: 'adsClicked', header: 'Ads Clicked', width: '11rem' },
  { field: 'adsDisplayed', header: 'Ads Displayed', width: '11rem' },
  { field: 'currentVersion', header: 'App Version', width: '13rem' },
];

export const yesNoTFDD: SelectItem[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
  { label: '---', value: undefined },
];

/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ListComponent} from "./list.component";
import {RouterModule} from "@angular/router";
import {TooltipModule} from "primeng/tooltip";
import {ButtonModule} from "primeng/button";
import {DropdownModule} from "primeng/dropdown";
import {FormsModule} from "@angular/forms";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {ImageModule} from "primeng/image";


@NgModule({
  imports: [CommonModule, RouterModule, TooltipModule, ButtonModule, DropdownModule, FormsModule, ProgressSpinnerModule, ImageModule],
  declarations: [ListComponent],
})
export class ListModule {}

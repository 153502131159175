/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {Component, OnInit} from '@angular/core';
import {FirebaseService} from "../../services/firebase.service";
import {MessageService} from "primeng/api";
import {NGXLogger} from "ngx-logger";
import {ActivatedRoute, Router} from "@angular/router";
import {getStateName} from "../place/place.conf";
import {BaseMapListComponent} from "../base-map-list/base-map-list.component";
import {statesDD, Tagline} from "./list.conf";
import {DomSanitizer} from "@angular/platform-browser";
import {Location} from '@angular/common';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent extends BaseMapListComponent implements OnInit {
  public sortByName: string = 'Sort by Name';
  public sortOldestFirst: string = 'Sort Oldest First';
  public sortNewestFirst: string = 'Sort Newest First';
  public sortTypeText: string = this.sortOldestFirst;
  public tagline?: Tagline;
  public readonly statesDD = statesDD;
  public selectedState: string;
  public filteredStateDD: Tagline[];

  constructor(
    protected readonly firebaseService: FirebaseService,
    protected readonly messageService: MessageService,
    protected readonly logger: NGXLogger,
    protected readonly route: ActivatedRoute,
    protected readonly router: Router,
    private readonly sanitizer: DomSanitizer,
    private readonly location: Location,
  ) {
    super(firebaseService, messageService, logger, route);
  }

  ngOnInit(): void {
    const stateParam = this.route.snapshot.params['state'].toUpperCase();
    this.state = getStateName(stateParam);
    this.selectedState = stateParam;
    this.filteredStateDD = statesDD.filter(state => state.mapUrl?.length > 0)
    if (this.state !== 'USA') {
      this.tagline = statesDD.find(state => state.value === stateParam);
      this.getPlacesProd(this.state);
    } else {
      this.router.navigate(['/404']).then();
    }
  }

  sortByNameFn() {
    this.sortTypeText = this.sortByName;
    this.places = this.places.sort((a, b) => {
      if (a.name > b.name) {
        return 1;  // `a` comes after `b`
      } else if (a.name < b.name) {
        return -1; // `a` comes before `b`
      } else {
        return 0;  // `a` and `b` are equal
      }
    });
  }

  sortNewestFirstFn() {
    this.sortTypeText = this.sortNewestFirst;
    this.places = this.places.sort((a, b) => b.id - a.id);
  }

  sortOldestFirstFn() {
    this.sortTypeText = this.sortOldestFirst;
    this.places = this.places.sort((a, b) => a.id - b.id);
  }

  domSanitizer(url: string | undefined) {
    if (url) {
      return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
    return null;
  }

  updateState(newState: string) {
    this.tagline = statesDD.find(state => state.value === newState);
    if (newState != this.selectedState && this.tagline != null && this.tagline.mapUrl?.length > 0) {
      if (this.tagline.value === 'VI') {
        this.state = 'USVI';
      } else {
        this.state = this.tagline.label;
      }
      this.selectedState = newState;
      this.getPlacesProd(this.state);
      const ns = newState.toLowerCase();
      this.router.navigate([`/list/${ns}`]).then();
    }
  }

  goBack() : void {
    this.location.back();
  }

  goToHomePage() {
    window.location.href = 'https://www.tagabrew.com';
    // this.router.navigate(['https://www.tagabrew.com']);  // Navigate to the 'home' page or any other route
  }
}

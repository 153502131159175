/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {SelectItem} from 'primeng/api';

export const defaultSortField = 'id';
export const defaultSortOrder = -1;

export const columnDefs = [
  { field: 'staged', header: 'staged',index: 0 , width: '8rem' },
  { field: 'id', header: 'id',index: 1 , width: '5.5rem' },
  { field: 'sequence', header: 'seq',index: 2 , width: '6rem' },
  { field: 'name', header: 'name',index: 3 , width: '20rem' },
  { field: 'address', header: 'address',index: 4 , width: '26rem' },
  { field: 'city', header: 'city',index: 5 , width: '12rem' },
  { field: 'state', header: 'state',index: 6 , width: '8rem' },
  { field: 'phone', header: 'phone',index: 7 , width: '9rem' },
  { field: 'latitude', header: 'latitude',index: 8 , width: '9rem' },
  { field: 'longitude', header: 'longitude',index: 9 , width: '9rem' },
  { field: 'tagStatus', header: 'brew',index: 10 , width: '8rem' },
  { field: 'cheerStatus', header: 'cheer',index: 11 , width: '8rem' },
  { field: 'charmStatus', header: 'charmStatus',index: 12 , width: '11rem' },
  { field: 'charmDesc', header: 'charmDesc',index: 13 , width: '16rem' },
  { field: 'websiteURL', header: 'websiteURL',index: 14 , width: '25rem' },
  { field: 'facebookURL', header: 'facebookURL',index: 15 , width: '25rem' },
  { field: 'twitterURL', header: 'twitterURL',index: 16 , width: '25rem' },
  { field: 'instagramURL', header: 'instagramURL',index: 17 , width: '25rem' },
  { field: 'untappd', header: 'untappd',index: 18 , width: '25rem' },
  { field: 'dog', header: 'dog',index: 19 , width: '8rem' },
  { field: 'rqc', header: 'rqc',index: 20 , width: '8rem' },
  { field: 'recycle', header: 'recycle',index: 21 , width: '8rem' },
  { field: 'logoUrl', header: 'logoUrl',index: 22 , width: '10rem' },
  { field: 'logoVersion', header: 'logoVersion',index: 23 , width: '10rem' },
  { field: 'createdDateValue', header: 'createdDate',index: 24 , width: '11rem' },
  { field: 'createdBy', header: 'createdBy',index: 25 , width: '10rem' },
  { field: 'updatedDateValue', header: 'updatedDate',index: 26 , width: '11rem' },
  { field: 'updatedBy', header: 'updatedBy',index: 27 , width: '10rem' },
];

export const mobileColumnDefs = [
  { field: 'staged', header: 'staged',index: 0 , width: '8rem' },
  { field: 'id', header: 'id',index: 1 , width: '5.5rem' },
  { field: 'sequence', header: 'seq',index: 2 , width: '6rem' },
  { field: 'name', header: 'name',index: 3 , width: '20rem' },
  { field: 'city', header: 'city',index: 5 , width: '12rem' },
  { field: 'state', header: 'state',index: 6 , width: '8rem' },
  { field: 'tagStatus', header: 'brew',index: 10 , width: '8rem' },
  { field: 'cheerStatus', header: 'cheer',index: 11 , width: '8rem' },
  { field: 'createdDateValue', header: 'createdDate',index: 24 , width: '11rem' },
  { field: 'createdBy', header: 'createdBy',index: 25 , width: '10rem' },
  { field: 'updatedDateValue', header: 'updatedDate',index: 26 , width: '11rem' },
  { field: 'updatedBy', header: 'updatedBy',index: 27 , width: '10rem' },
];

export const mapsColumnDefs = [
  { field: 'name', header: 'Name' },
  { field: 'address', header: 'Address' },
  { field: 'phone', header: 'Phone Number' },
  { field: 'tagStatus', header: 'TagaBrew' },
  { field: 'cheerStatus', header: 'TagaCheer' },
  { field: 'dog', header: 'Dog Friendly', width: '8rem' },
  { field: 'recycle', header: 'Recycle', width: '8rem' },
  { field: 'websiteURL', header: 'Website' },
  { field: 'facebookURL', header: 'Facebook' },
  { field: 'twitterURL', header: 'Twitter' },
  { field: 'instagramURL', header: 'Instagram' },
  { field: 'untappd', header: 'Untappd' },
  { field: 'coordinates', header: 'Coordinates' },
  { field: 'category', header: 'Category' },
];

export const stateGps = [
  { name: 'Wisconsin', lat: 44.500000, long: -89.500000 },
  { name: 'West Virginia', lat: 39.000000, long: -80.500000 },
  { name: 'Vermont', lat: 44.000000, long: -72.699997 },
  { name: 'Texas', lat: 31.000000, long: -100.000000 },
  { name: 'South Dakota', lat: 44.500000, long: -100.000000 },
  { name: 'Rhode Island', lat: 41.742325, long: -71.742332 },
  { name: 'Oregon', lat: 44.000000, long: -120.500000 },
  { name: 'New York', lat: 43.000000, long: -75.000000 },
  { name: 'New Hampshire', lat: 44.000000, long: -71.500000 },
  { name: 'Nebraska', lat: 41.500000, long: -100.000000 },
  { name: 'Kansas', lat: 38.500000, long: -98.000000 },
  { name: 'Mississippi', lat: 33.000000, long: -90.000000 },
  { name: 'Illinois', lat: 40.000000, long: -89.000000 },
  { name: 'Delaware',lat: 39.000000, long: -75.500000 },
  { name: 'Connecticut', lat: 41.599998, long: -72.699997 },
  { name: 'Arkansas', lat: 34.799999, long: -92.199997 },
  { name: 'Indiana', lat: 40.273502, long: -86.126976 },
  { name: 'Missouri', lat: 38.573936, long: -92.603760 },
  { name: 'Florida', lat: 27.994402, long: -81.760254 },
  { name: 'Nevada', lat: 39.876019, long: -117.224121 },
  { name: 'Maine',lat: 45.367584, long: -68.972168 },
  { name: 'Michigan', lat: 44.182205, long: -84.506836 },
  { name: 'Georgia', lat: 33.247875, long: -83.441162 },
  { name: 'Hawaii', lat: 19.741755, long: -155.844437 },
  { name: 'Alaska', lat: 66.160507, long: -153.369141 },
  { name: 'Tennessee', lat: 35.860119, long: -86.660156 },
  { name: 'Virginia', lat: 37.926868, long: -78.024902 },
  { name: 'New Jersey', lat: 39.833851, long: -74.871826 },
  { name: 'Kentucky', lat: 37.839333, long: -84.270020 },
  { name: 'North Dakota', lat: 47.650589, long: -100.437012 },
  { name: 'Minnesota', lat: 46.392410, long: -94.636230 },
  { name: 'Oklahoma', lat: 36.084621, long: -96.921387 },
  { name: 'Montana', lat: 46.965260, long: -109.533691 },
  { name: 'Washington', lat: 47.751076, long: -120.740135 },
  { name: 'Utah', lat: 39.419220, long: -111.950684 },
  { name: 'Colorado', lat: 39.113014, long: -105.358887 },
  { name: 'Ohio', lat: 40.367474, long: -82.996216 },
  { name: 'Alabama', lat: 32.318230, long: -86.902298 },
  { name: 'Iowa',lat: 42.032974, long: -93.581543 },
  { name: 'New Mexico', lat: 34.307144, long: -106.018066 },
  { name: 'South Carolina', lat: 33.836082, long: -81.163727 },
  { name: 'Pennsylvania', lat: 41.203323, long: -77.194527 },
  { name: 'Arizona', lat: 34.048927, long: -111.093735 },
  { name: 'Maryland', lat: 39.045753, long: -76.641273 },
  { name: 'Massachusetts', lat: 42.407211, long: -71.382439 },
  { name: 'California', lat: 36.778259, long: -119.417931 },
  { name: 'Idaho', lat: 44.068203, long: -114.742043 },
  { name: 'Wyoming', lat: 43.075970, long: -107.290283 },
  { name: 'North Carolina', lat: 35.782169, long: -80.793457 },
  { name: 'Louisiana', lat: 30.391830, long: -92.329102 },
  { name: 'USA', lat: 38.500000, long: -98.000000 }, // Kansas - Close US center
];

export const statesDD: SelectItem[] = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
  { label: 'USVI', value: 'VI' },
  // { label: 'St Croix', value: 'St Croix' },
  // { label: 'St John', value: 'St John' },
  // { label: 'St Thomas', value: 'St Thomas' },
  { label: 'USA', value: 'US' },
];

export const statusDD: SelectItem[] = [
  { label: 'Active', value: 'A' },
  { label: 'Out of Stock', value: 'O' },
  { label: 'Soon', value: 'S' },
  { label: 'Temp Closed', value: 'T' },
  { label: 'Retired', value: 'R' },
];

export const statusWithHiddenDD: SelectItem[] = [
  { label: 'Active', value: 'A' },
  { label: 'Out of Stock', value: 'O' },
  { label: 'Soon', value: 'S' },
  { label: 'Temp Closed', value: 'T' },
  { label: 'Retired', value: 'R' },
  { label: 'Hidden', value: 'H' },
];

export const yesNoDD: SelectItem[] = [
  { label: 'Yes', value: 'Y' },
  { label: 'No', value: 'N' },
];

export const yesNoTFDD: SelectItem[] = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const charmStatusDD: SelectItem[] = [
  { label: 'Active', value: 'A' },
  { label: 'Retired', value: 'R' },
];

export const rqcsDD: SelectItem[] = [
  { label: 'Closed', value: 'C' },
  { label: 'Quit', value: 'Q' },
];

/**
 * Capitalizes string.  First letter uppercase, rest lowercase
 * @param value string to capitalize
 * @returns capitalized string
 */
export function capitalization(value: string): string {
  if (value !== null) {
    const first = value.substr(0, 1).toUpperCase();
    return first + value.substr(1).toLowerCase();
  }
  return value;
}

/**
 * Get state's abbreviation from name
 * @param state state's name
 * @returns state's abbreviation
 */
export function getStateAbbreviation(state: string): string {
  const index = statesDD.findIndex((stateDD) => {
    return stateDD.label!.toLowerCase() === state?.toLowerCase();
  });
  return index !== -1 ? statesDD[index].value : capitalization(state);
}

/**
 * Gets the state's name from abbreviation
 * @param state state's abbreviation
 * @returns state's name
 */
export function getStateName(state: string): string {
  let stateName = '';

  // Look for by abbreviation
  let stateDD = statesDD.find((stateDD) => {
    return stateDD.value.toLowerCase() === state?.toLowerCase();
  });

  if (stateDD) {
    stateName = stateDD.label!;
  } else {
    // Look for by name
    stateDD = statesDD.find((stateDD) => {
      return stateDD.label?.toLowerCase() === state?.toLowerCase();
    });

    if (stateDD) {
      stateName = stateDD.label!;
    }
  }

  return stateName;
}


/**
 * Gets the status description from abbreviation
 * @param status status abbreviation
 * @returns status description
 */
export function getStatusDescription(status?: string): string {
  return statusDD.find(res => res.value === status)?.label ?? 'Unknown';
}

/**
 * Gets the state's GPS abbreviation
 * @param state state's abbreviation
 * @returns state's GPS
 */
export function getStateGps(state: string): any {
  const gps = stateGps.find(res => res.name.toLowerCase() === state.toLowerCase());
  if (gps != null) {
    return gps;
  }
  return {name: 'USA', lat: 36.2462876, long: -113.7227724};
}

<!--
  ~ Copyright © MEFTEK LLC. All rights reserved.
  ~ This information is confidential and proprietary to MEFTEK LLC
  ~ and may not be used, modified, copied or distributed.
  -->

<p-toast key="bc" position="bottom-center"></p-toast>

<ng-container *ngIf="initialized && !canView">
  <h1 class="blink_me" style="color: red;">You must be authorized by the site admin to use this site.</h1>
  <h3 *ngIf="authService.userData as user">You are logged in with email: {{ user.email }}<br><br>Contact the site
    admin.</h3>
  <div class="flex justify-content-center text-sm mt-2 sm:mt-0">
    <a (click)="authService.SignOut()" class="nav-link underline">
      <i class="pi pi-sign-out"></i>&nbsp;Sign out
    </a>
  </div>
</ng-container>
<ng-container *ngIf="canView">
  <p-table
    #userProfileTable
    (onFilter)="handleFilter($event)"
    (onSort)="handleSort($event)"
    [(selection)]="selectedUsers"
    [columns]="selectedColumns"
    [filters]="filters"
    [globalFilterFields]="[
      'displayName',
      'createdOn',
      'email',
      'lastUsedOn',
      'uid',
      'zipCode',
    ]"
    [loading]="loading"
    [paginator]="true"
    [reorderableColumns]="true"
    [resizableColumns]="true"
    [responsive]="true"
    [rowHover]="true"
    [rowsPerPageOptions]="[100, 250, 500, 1000, 2500, { showAll: 'All' }]"
    [rows]="250"
    [scrollHeight]="'flex'"
    [scrollable]="true"
    [showCurrentPageReport]="true"
    [showLoader]="false"
    [sortField]="sortField"
    [sortOrder]="sortOrder"
    [value]="users"
    breakpoint="680px"
    columnResizeMode="expand"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
    dataKey="uid"
    loadingIcon="pi pi-spin pi-spinner loading-icon-size"
    responsiveLayout="stack"
    scrollDirection="both"
    sortMode="single"
    styleClass="p-datatable-sm p-datatable-gridlines tb-table px-2 pb-2"
  >
    <!-- CAPTION -->
    <ng-template pTemplate="caption">
      <p-toolbar>
        <div class="p-toolbar-group-left">
          <p-image
            *ngIf="!isMobile"
            alt="Tagabrew Logo"
            class="mr-2 lg:hide-me"
            height="40"
            src="assets/tagabrew.png"
          ></p-image>
          <p-button
            (onClick)="handleClearFilter(userProfileTable); gf.value = ''"
            [disabled]="!isFiltered"
            [icon]="isFiltered ? 'pi pi-filter-slash' : 'pi pi-filter'"
            [ngStyle]="{ cursor: isFiltered ? 'pointer' : 'not-allowed' }"
            [pTooltip]="isFiltered ? 'Clear Filters' : ''"
            styleClass="p-button-info mr-2"
            tooltipPosition="right"
          ></p-button>
          <span class="p-input-icon-left w-full mr-2">
            <i
              class="pi pi-search"
              pTooltip="Filter Table ..."
              tooltipPosition="right"
            ></i>
            <input
              #gf
              (input)="globalFilter(userProfileTable, $event)"
              [disabled]="false"
              class="w-inherit"
              pInputText
              placeholder="Search keyword"
              type="text"
            />
          </span>
          <p-multiSelect
            [(ngModel)]="selectedColumns"
            [disabled]="false"
            [options]="columns"
            [style]="{minWidth: '200px'}"
            optionLabel="header"
            placeholder="Choose Columns"
            selectedItemsLabel="{0} columns selected"
          ></p-multiSelect>
        </div>
        <div class="p-toolbar-group-left">
          <p-button
            (onClick)="handlePlaces()"
            [disabled]="false"
            [ngStyle]="{ cursor: 'pointer' }"
            icon="pi pi-building"
            pTooltip="Show Places"
            styleClass="p-button-success ml-2"
            tooltipPosition="left"
          ></p-button>
          <p-button
            [disabled]="true"
            [ngStyle]="{ cursor: 'not-allowed' }"
            icon="pi pi-users"
            styleClass="ml-2"
          ></p-button>
          <p-button
            (onClick)="handleAds()"
            [disabled]="false"
            [ngStyle]="{ cursor: 'pointer' }"
            [pTooltip]=" 'Show Ads'"
            icon="pi pi-chart-bar"
            styleClass="p-button-secondary ml-2"
            tooltipPosition="left"
          ></p-button>
          <p-button
            (onClick)="handleDelete()"
            [disabled]="selectedUsers?.length === 0"
            icon="pi pi-trash"
            styleClass="hidden p-button-danger ml-2"
            type="button"
          ></p-button>
          <p-button
            (onClick)="handleCsvExport()"
            [disabled]="false"
            icon="pi pi-download"
            pTooltip="Download User List"
            styleClass="p-button-help ml-2"
            tooltipPosition="left">
          </p-button>
          <p-button
            (onClick)="authService.SignOut()"
            [disabled]="false"
            [pTooltip]="'Sign-Out'"
            icon="pi pi-sign-out"
            styleClass="p-button-danger ml-2"
            tooltipPosition="left">
          </p-button>
        </div>
      </p-toolbar>
    </ng-template>

    <!-- HEADER -->
    <ng-template let-columns pTemplate="header">
      <tr>
        <!--            <th style="width: 3rem;">-->
        <!--              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>-->
        <!--            </th>-->
        <th class="no-column">
          No.
          <p-columnFilter
            display="menu"
            field="index"
            type="numeric"
          ></p-columnFilter>
        </th>
        <th
          *ngFor="let col of columns"
          [pSortableColumn]="col.field"
          [style.width]="col.width"
          pReorderableColumn
          pResizableColumn
        >
          <div [ngSwitch]="true" class="p-d-flex p-jc-between p-ai-center">
            {{ col.header }}
            <p-sortIcon [field]="col.field"></p-sortIcon>
            <!-- boolean -->
            <p-columnFilter
              *ngSwitchCase="isBooleanColumn(col.field)"
              [field]="col.field"
              [showAddButton]="false"
              [showMatchModes]="false"
              [showOperator]="false"
              display="menu"
              matchMode="in"
            >
              <ng-template pTemplate="header">
                <div class="mt-2 ml-2 font-bold">Boolean Filter</div>
              </ng-template>
              <ng-template
                let-filter="filterCallback"
                let-value
                pTemplate="filter"
              >
                <p-multiSelect
                  (onChange)="filter($event.value)"
                  [ngModel]="value"
                  [options]="yesNoTfDD"
                  [placeholder]="pascalCase(col.field) + '...'"
                  optionLabel="label"
                  optionValue="value"
                >
                  <ng-template let-option pTemplate="item">
                    <div class="p-multiselect-representative-option">
                      <span class="p-ml-1">{{ option.label }}</span>
                    </div>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
            <!-- date -->
            <p-columnFilter
              *ngSwitchCase="isDateColumn(col.field)"
              [field]="col.field"
              display="menu"
              type="date"
            ></p-columnFilter>
            <!-- numeric -->
            <p-columnFilter
              *ngSwitchCase="isNumericColumn(col.field)"
              [field]="col.field"
              display="menu"
              type="numeric"
            ></p-columnFilter>
            <!-- default/text -->
            <p-columnFilter
              *ngSwitchDefault
              [field]="col.field"
              display="menu"
              matchMode="contains"
              type="text"
            ></p-columnFilter>
          </div>
        </th>
      </tr>

      <!-- LOADING -->
      <tr *ngIf="loading">
        <td class="w-screen" colspan="22">
          <p-progressBar
            [style]="{ height: '0.4rem' }"
            mode="indeterminate"
          ></p-progressBar>
        </td>
      </tr>
    </ng-template>

    <!-- LOADING BODY -->
    <ng-template pTemplate="loadingbody">
      <ng-container *ngIf="loading && loadingBody && !users?.length">
        <tr *ngFor="let _ of [1, 2, 3, 4, 5]" class="loading-height">
          <td
            *ngFor="let column of columns"
            [ngStyle]="{ flex: '0 0 ' + column.width }"
          >
            <div class="loading-flex">
              <p-skeleton class="w-full"></p-skeleton>
            </div>
          </td>
        </tr>
      </ng-container>
    </ng-template>

    <!-- BODY -->
    <ng-template let-columns="columns" let-rowIndex="rowIndex" let-user pTemplate="body">
      <tr [ngClass]="user['emailVerified'] ? '' : 'user-not-verified'">
        <!--            <td style="width: 3rem;">-->
        <!--              <p-tableCheckbox [value]="user"></p-tableCheckbox>-->
        <!--            </td>-->
        <td class="no-column">
          {{ rowIndex }}
        </td>
        <td
          *ngFor="let col of columns"
          [style.width]="col.width"
        >
          <span class="p-column-title">{{ col.header }}</span>
          <ng-container [ngSwitch]="true">
            <!-- createdDate, updatedDate -->
            <ng-container *ngSwitchCase="col.field === 'createdOn' || col.field === 'lastUsedOn'">
              {{ user[col.field] ?? '---' }}
            </ng-container>
            <!-- boolean -->
            <ng-container *ngSwitchCase="isBooleanColumn(col.field)">
              {{ user[col.field] | booleanToYesNo }}
            </ng-container>
            <!-- default/string -->
            <ng-container *ngSwitchDefault>
              {{ user[col.field] ?? '---' }}
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </ng-template>

    <!-- SUMMARY -->
    <ng-template pTemplate="summary">
      <app-table-summary [hideLegend]="true"></app-table-summary>
    </ng-template>
  </p-table>
</ng-container>

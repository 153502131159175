<!--
  ~ Copyright © MEFTEK LLC. All rights reserved.
  ~ This information is confidential and proprietary to MEFTEK LLC
  ~ and may not be used, modified, copied or distributed.
  -->

<!-- HEADER -->
<div class="ml-8 mt-3">
<p-image class="mt-3" src="assets/tagabrew.png"  alt="TagaBrew Logo" height="56px"></p-image>
<span>
  <p-button
    (onClick)="goToHomePage()"
    class="ml-8"
    label="Home"
    styleClass="p-button-text home-btn"
  ></p-button>
</span>
</div>

<div class="text-center my-3">
  <div class="text-xl tagline-tagline">Welcome to the {{ tagline?.label }} Trail</div>
  <div class="text-6xl tagline-label">{{ tagline?.label }} Trail</div>
  <div [innerHTML]="tagline?.tagline" class="text-2xl tagline-tagline"></div>
  <div>
    <label class="mr-3 text-xl" for="state-dd">Selected State:</label>
    <p-dropdown
      (ngModelChange)="updateState($event)"
      [ngModel]="selectedState"
      [options]="filteredStateDD"
      id="state-dd"
      optionLabel="label"
      optionValue="value"
      styleClass="w-20rem mt-2"
    ></p-dropdown>
  </div>
</div>

<!-- Loading -->
<ng-container *ngIf="loading">
  <p-progressSpinner class="p-6 flex align-items-center"></p-progressSpinner>
</ng-container>

<!-- Not Loading -->
<ng-container *ngIf="!loading">

  <!-- Page cannot be shown -->
  <ng-container *ngIf="!showPage">
    <div class="get-moving-text text-lg">{{ getMovingText }}</div>
  </ng-container>

  <!-- Page can be shown -->
  <ng-container *ngIf="showPage">

    <!-- MAP -->
    <div class="map-container">
      <iframe
        *ngIf="tagline?.mapUrl"
        [src]="domSanitizer(tagline?.mapUrl)"
        height="480" width="100%"
      ></iframe>
    </div>

    <!-- Buttons -->
    <div class="container">
      <div class="content">
        <p-button
          (onClick)="sortOldestFirstFn()"
          [disabled]="sortTypeText === sortOldestFirst"
          [label]="sortOldestFirst"
          [ngClass]="sortTypeText === sortOldestFirst ? 'cursor-not-allowed' : 'cursor-pointer'"
          styleClass="p-button-warning p-button-raised w-10rem"
        ></p-button>
        <p-button
          (onClick)="sortNewestFirstFn()"
          [disabled]="sortTypeText === sortNewestFirst"
          [label]="sortNewestFirst"
          [ngClass]="sortTypeText === sortNewestFirst ? 'cursor-not-allowed' : 'cursor-pointer'"
          styleClass="p-button-warning p-button-raised w-10rem"
        ></p-button>
        <p-button
          (onClick)="sortByNameFn()"
          [disabled]="sortTypeText === sortByName"
          [label]="sortByName"
          [ngClass]="sortTypeText === sortByName ? 'cursor-not-allowed' : 'cursor-pointer'"
          styleClass="p-button-warning p-button-raised w-10rem"
        ></p-button>
      </div>
    </div>

    <!-- LIST -->
    <div class="container">
      <div class="content">
        <div class="card text-lg">
          <ol>
            <ng-container *ngFor="let place of places">
              <li *ngIf="isNotRetiredOrHidden(place)">
        <span>
          <span *ngIf="place.websiteURL">
            <a [href]="place.websiteURL" target="_blank">{{ place.name }}</a>  / {{ place.city }}</span>
          <span *ngIf="!place.websiteURL">{{ place.name }} / {{ place.city }}</span>
        </span>
                <span *ngIf="hasCharm(place)" pTooltip="Charm" style="color: blue;">
          ({{ place.charmDesc }})
        </span>
                <span *ngIf="isSoon(place)" class="font-bold" pTooltip="Coming Soon" style="color: goldenrod;">
          &quot;SOON&quot;
        </span>
                <span class="font-bold" pTooltip="Has TagaCheer" style="color: rebeccapurple;">
          {{ isCheer(place) ? 'CHEER' : '' }}
        </span>
              </li>
            </ng-container>
          </ol>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<!--
  ~ Copyright © MEFTEK LLC. All rights reserved.
  ~ This information is confidential and proprietary to MEFTEK LLC
  ~ and may not be used, modified, copied or distributed.
  -->

<p-dialog
  (onShow)="onShow()"
  *ngIf="visible"
  [(visible)]="visible"
  [modal]="true"
  [style]="{width: '95vw'}"
  class="h-full"
>
  <ng-template pTemplate="header">
    <div class="container align-content-center">
      <app-file-upload
        (imageDataUrlChange)="imageDataUrlChange($event)"
        (imageFilenameChange)="imageFilenameChange($event)"
        [logoUrl]="logoUrl$"
      ></app-file-upload>
    </div>
    <div>
      <div class="font-bold text-lg md:text-3xl lg:text-4xl">{{currentPlace.name}}</div>
      <div class="font-bold">{{currentPlace.address}}</div>
    </div>
  </ng-template>

  <div class="card">
    <form #placeForm="ngForm" (ngSubmit)="placeForm.form.valid && handleSubmit()" novalidate>
      <div class="formgrid grid p-fluid">
        <!-- Row 1 - id:1, seq:1, status:2, name:4, address:4 -->
        <!-- id -->
        <div class="field col-6 md:col-2 lg:col-2">
          <!--suppress XmlInvalidId -->
          <label [ngClass]="{ required: true }" for="id">Id</label>
          <p-inputNumber
            #id="ngModel"
            [(ngModel)]="currentPlace.id"
            [disabled]="true"
            [required]="true"
            inputId="id"
            name="id"
            placeholder="id"
            styleClass="form-control"
            pTooltip="id (readonly)"
          ></p-inputNumber>
          <small *ngIf="id.errors?.['required']" class="p-error">Id is required</small>
        </div>

        <!-- seq -->
        <div class="field col-6 md:col-2 lg:col-2">
          <!--suppress XmlInvalidId -->
          <label [ngClass]="{ required: true }" for="seq">Seq</label>
          <p-inputNumber
            #seq="ngModel"
            (ngModelChange)="onChangeSequence($event)"
            [(ngModel)]="currentPlace.sequence"
            [disabled]="true"
            [required]="true"
            inputId="seq"
            name="seq"
            placeholder="seq"
            pTooltip="sequence (readonly)"
            styleClass="form-control"
          ></p-inputNumber>
          <small *ngIf="seq.errors?.['required']" class="p-error">Seq is required</small>
        </div>

        <!-- name -->
        <div class="field col-12 md:col-8 lg:col-4">
          <label [ngClass]="{ required: true }" for="name">Name</label>
          <input
            #name="ngModel"
            (ngModelChange)="onChangeName($event)"
            [(ngModel)]="currentPlace.name"
            class="inputfield w-full form-control"
            id="name"
            name="name"
            pInputText
            placeholder="Enter name..."
            required
            type="text"
          />
          <small *ngIf="name.errors?.['required']" class="p-error">Name is required</small>
        </div>

        <!-- address -->
        <div class="field col-12 md:col-4 lg:col-4">
          <label [ngClass]="{ required: true }" for="address">Address</label>
          <input
            #address="ngModel"
            (blur)="handleBlurAddress()"
            (ngModelChange)="onChangeAddress($event)"
            [(ngModel)]="currentPlace.address"
            class="inputfield w-full form-control"
            id="address"
            name="address"
            pInputText
            placeholder="Enter address..."
            required
            type="text"
          />
          <small
            *ngIf="address.errors?.['required'] || street.errors?.['required']
                     || city.errors?.['required'] || state.errors?.['required']
                     || zip.errors?.['required']"
            class="p-error"
          >
            Address is required
          </small>
        </div>

        <!-- Row 2 - street:3, city:3, state:2, zip:2, phone:2 -->
        <!-- street -->
        <div class="field col-12 md:col-4 lg:col-3">
          <label [ngClass]="{ required: true }" for="street">Street</label>
          <input
            #street="ngModel"
            (ngModelChange)="onChangeStreetCityStateZip()"
            [(ngModel)]="currentPlace.street"
            class="inputfield w-full form-control"
            id="street"
            name="street"
            pInputText
            placeholder="Enter street..."
            required
            type="text"
          />
          <small *ngIf="street.errors?.['required']" class="p-error">Street is required</small>
        </div>

        <!-- city -->
        <div class="field col-6 md:col-4 lg:col-3">
          <label [ngClass]="{ required: true }" for="city">City</label>
          <input
            #city="ngModel"
            (ngModelChange)="onChangeStreetCityStateZip()"
            [(ngModel)]="currentPlace.city"
            class="inputfield w-full form-control"
            id="city"
            name="city"
            pInputText
            placeholder="Enter city..."
            required
            type="text"
          />
          <small *ngIf="city.errors?.['required']" class="p-error">City is required</small>
        </div>

        <!-- state -->
        <div class="field col-6 md:col-3 lg:col-2">
          <label [ngClass]="{ required: true }" for="state">State</label>
          <div>
            <p-dropdown
              #state="ngModel"
              (ngModelChange)="onChangeState($event)"
              [(ngModel)]="currentPlace.state"
              [filter]="true"
              [options]="statesDD"
              [required]="true"
              [showClear]="true"
              filterBy="name"
              id="state"
              name="state"
              optionValue="label"
              placeholder="Select..."
              styleClass="w-full form-control"
            >
            </p-dropdown>
          </div>
          <div *ngIf="isFieldInvalid(state)" class="alert">
            <small *ngIf="state.errors?.['required']" class="p-error"
            >State is required</small
            >
          </div>
        </div>

        <!-- zip -->
        <div class="field col-6 md:col-2 lg:col-2">
          <label [ngClass]="{ required: true }" for="zip">Zip</label>
          <input
            #zip="ngModel"
            (ngModelChange)="onChangeStreetCityStateZip()"
            [(ngModel)]="currentPlace.zip"
            class="inputfield w-full form-control"
            id="zip"
            name="zip"
            pInputText
            pattern="\d{5}(?:[-]\d{4})?"
            placeholder=" Enter zip..."
            required
            type="text"
          />
          <div *ngIf="isFieldInvalid(zip)" class="alert">
            <small *ngIf="zip.errors?.['pattern']" class="p-error">
              zip format (12345[-1234]
            </small>
            <small *ngIf="zip.errors?.['required']" class="p-error"
            >Zip is required</small
            >
          </div>
        </div>

        <!-- phone -->
        <div class="field col-6 md:col-3 lg:col-2">
          <label for="phone">Phone</label>
          <p-inputMask
            #phone="ngModel"
            (ngModelChange)="onChangePhone($event)"
            [(ngModel)]="currentPlace.phone"
            class="inputfield w-full form-control"
            id="phone"
            mask="(999) 999-9999"
            name="phone"
            placeholder="(999) 999-9999"
          ></p-inputMask>
          <small *ngIf="phone.errors?.['required']" class="p-error">Phone is required</small>
        </div>

        <!-- Row 3 - coordinates:4, latitude:4, longitude:4 -->
        <!-- coordinates -->
        <div class="field col-12 md:col-4 lg:col-4">
          <label [ngClass]="{ required: true }" for="coordinates"
          >Coordinates</label
          >
          <input
            #coordinates="ngModel"
            (ngModelChange)="onChangeCoordinates($event)"
            [(ngModel)]="currentPlace.coordinates"
            (blur)="handleBlurCoordinates()"
            class="inputfield w-full form-control"
            id="coordinates"
            name="coordinates"
            pattern="^-*\d+\.\d{2,}\,-*\d+\.\d{2,}$"
            pInputText
            placeholder="Enter coordinates..."
            required
            type="text"
          />
          <small>{{currentPlace.latitude}},{{currentPlace.longitude}}</small>
            <small
              *ngIf="coordinates.errors?.['required'] || latitude.errors?.['required'] || longitude.errors?.['required']"
              class="p-error"
            >
              Coordinates are required
            </small>
            <small *ngIf="coordinates.errors?.['pattern']" class="p-error">Coordinates format: [-0.00,-0.00]</small>
        </div>

        <!-- latitude -->
        <div class="field col-6 md:col-6 lg:col-4">
          <!--suppress XmlInvalidId -->
          <label [ngClass]="{ required: true }" for="latitude">Latitude</label>
<!--          (ngModelChange)="onChangeLatLon($event, 'lat')"-->
          <p-inputNumber
            #latitude="ngModel"
            (onInput)="onChangeLatLon($event, 'lat')"
            [(ngModel)]="currentPlace.latitude"
            [maxFractionDigits]="8"
            [minFractionDigits]="2"
            [required]="true"
            inputId="latitude"
            mode="decimal"
            name="latitude"
            placeholder="Enter latitude..."
            styleClass="form-control"
          ></p-inputNumber>
          <small *ngIf="latitude.errors?.['required']" class="p-error">Latitude is required</small>
        </div>

        <!-- longitude -->
        <div class="field col-6 md:col-6 lg:col-4">
          <!--suppress XmlInvalidId -->
          <label [ngClass]="{ required: true }" for="longitude">Longitude</label>
          <p-inputNumber
            #longitude="ngModel"
            (onInput)="onChangeLatLon($event, 'lon')"
            [(ngModel)]="currentPlace.longitude"
            [maxFractionDigits]="8"
            [minFractionDigits]="2"
            [required]="true"
            inputId="longitude"
            mode="decimal"
            name="longitude"
            placeholder="Enter longitude..."
            styleClass="form-control"
          ></p-inputNumber>
          <small *ngIf="longitude.errors?.['required']" class="p-error">Longitude is required</small>
        </div>

        <!-- Row 4 - charm:2, charmDesc:4, cheer:1, dogFriendly:1, recycle:1 isRetired:2 -->
        <!-- tagstatus -->
        <div class="field col-4 md:col-3 lg:col-3">
          <label [ngClass]="{ required: !hasStatus }" for="tagstatus">
            Brew <small><i>({{setStatus1(currentPlace.tagStatus, currentPlace.cheerStatus) ?? 'undefined'}})</i></small>
          </label>
          <p-dropdown
            #tagstatus="ngModel"
            (ngModelChange)="onChangeTagStatus($event)"
            [(ngModel)]="currentPlace.tagStatus"
            [options]="statusDD"
            [required]="!hasStatus"
            [showClear]="true"
            id="tagstatus"
            name="tagstatus"
            placeholder="Select..."
            pTooltip="Select Brew Status"
            styleClass="w-full form-control"
          ></p-dropdown>
          <div *ngIf="isTagStatusAndCheerStatusInvalid(tagstatus, cheerstatus)" class="alert">
            <small *ngIf="tagstatus?.errors?.['required']" class="p-error">Brew or Cheer is required</small>
          </div>
        </div>

        <!-- charm -->
        <div class="field col-4 md:col-3 lg:col-3">
          <!--suppress XmlInvalidId -->
          <label for="charm">Charm</label>
          <p-dropdown
            #charm="ngModel"
            (ngModelChange)="onChangeCharm($event)"
            [(ngModel)]="currentPlace.charm"
            [options]="charmStatusDD"
            [showClear]="true"
            inputId="charm"
            name="charm"
            placeholder="Select..."
            pTooltip="Select Charm Status"
            styleClass="w-full form-control"
          ></p-dropdown>
        </div>

        <!-- charm description -->
        <div class="field col-4 md:col-3 lg:col-3">
          <!--suppress XmlInvalidId -->
          <label [ngClass]="{ required: hasCharm }" for="charm-desc">Charm Descr</label>
          <input
            #charmdesc="ngModel"
            (ngModelChange)="onChangeCharmDesc($event)"
            [(ngModel)]="currentPlace.charmDesc"
            [disabled]="!hasCharm"
            [required]="charm?.value !== ''"
            class="inputfield w-full form-control"
            id="charmdesc"
            name="charmdesc"
            pInputText
            placeholder="Enter charm desciption..."
            type="text"
          />
           <small *ngIf="charm?.value !== '' && charmdesc.errors?.['required']" class="p-error">Charm Description is required</small>
        </div>

        <!-- cheerStatus -->
        <div class="field col-4 md:col-3 lg:col-3">
          <label [ngClass]="{ required: !hasStatus }" for="cheerstatus">
            Cheer <small><i>({{setCheer1(currentPlace.cheerStatus) ?? 'undefined'}})</i></small>
          </label>
          <p-dropdown
            #cheerstatus="ngModel"
            (ngModelChange)="onChangeCheerStatus($event)"
            [(ngModel)]="currentPlace.cheerStatus"
            [options]="statusDD"
            [required]="!hasStatus"
            [showClear]="true"
            id="cheerstatus"
            name="cheerstatus"
            placeholder="Select..."
            pTooltip="Select Cheer Status"
            styleClass="w-full form-control"
          ></p-dropdown>
          <div *ngIf="isTagStatusAndCheerStatusInvalid(tagstatus, cheerstatus)" class="alert">
            <small *ngIf="cheerstatus.errors?.['required']" class="p-error">Brew or Cheer is required</small>
          </div>
        </div>

        <!-- dog friendly -->
        <div class="field col-2 md:col-3 lg:col-3">
          <!--suppress XmlInvalidId -->
          <label for="dog-friendly">Dogs</label>
          <div class="w-full">
            <p-inputSwitch
              (ngModelChange)="onChangeDog($event)"
              [(ngModel)]="isDogFriendly"
              id="dogfriendly"
              name="dogfriendly"
              pTooltip="Select Dog Friendly"
              styleClass="mt-1 form-control"
            ></p-inputSwitch>
          </div>
        </div>

        <!-- recycle -->
        <div class="field col-2 md:col-3 lg:col-3">
          <!--suppress XmlInvalidId -->
          <label for="recycle">Recycle</label>
          <div class="w-full">
            <p-inputSwitch
              (ngModelChange)="onChangeRecycle($event)"
              [(ngModel)]="recycle"
              id="recycle"
              name="recycle"
              pTooltip="Recycle drop-off"
              styleClass="mt-1 form-control"
            ></p-inputSwitch>
          </div>
        </div>

        <!-- is retired -->
        <div class="field col-4 md:col-3 lg:col-3">
          <label [ngClass]="{ required: isRetired }" for="rqc">Why Retired?</label>
          <p-dropdown
            #rqc="ngModel"
            (ngModelChange)="onChangeRqc($event)"
            [(ngModel)]="currentPlace.rqc"
            [disabled]="!isRetired"
            [options]="rqcsDD"
            [showClear]="true"
            id="rqc"
            name="rqc"
            placeholder="Select..."
            pTooltip="Select Why Retired?"
            styleClass="w-full form-control"
          ></p-dropdown>
          <small *ngIf="rqc.errors?.['required']" class="p-error">Why Retired is invalid</small>
        </div>

        <!-- Row 5 - websiteURL:4, facebookURL:4, twitterURL:4 -->
        <!-- websiteURL -->
        <div class="field col-12 md:col-4 md:col-4">
          <label for="websiteurl">Website URL</label>
          <input
            #websiteurl="ngModel"
            (ngModelChange)="onChangeWebsiteURL($event)"
            [(ngModel)]="currentPlace.websiteURL"
            class="inputfield w-full form-control"
            id="websiteurl"
            name="websiteurl"
            pInputText
            pattern="https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"
            placeholder="https://www.website.com"
            type="text"
          />
          <small *ngIf="websiteurl.errors?.['pattern']" class="p-error">Website URL is invalid</small>
        </div>

        <!-- facebookURL -->
        <div class="field col-12 md:col-4 md:col-4">
          <label for="facebookurl">Facebook URL</label>
          <input
            #facebookurl="ngModel"
            (ngModelChange)="onChangeFacebookURL($event)"
            [(ngModel)]="currentPlace.facebookURL"
            [placeholder]="facebookUrlPlaceholder"
            class="inputfield w-full form-control"
            id="facebookurl"
            name="facebookurl"
            pInputText
            pattern="http(s)?:\/\/(www\.)?facebook.com\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"
            type="text"
          />
          <small *ngIf="facebookurl.errors?.['pattern']" class="p-error">Facebook URL is invalid</small>
        </div>

        <!-- twitterURL -->
        <div class="field col-12 md:col-4 md:col-4">
          <label for="twitterurl">Twitter URL</label>
          <input
            #twitterurl="ngModel"
            (ngModelChange)="onChangeTwitterURL($event)"
            [(ngModel)]="currentPlace.twitterURL"
            [placeholder]="twitterUrlPlaceholder"
            class="inputfield w-full form-control"
            id="twitterurl"
            name="twitterurl"
            pInputText
            pattern="http(s)?:\/\/(www\.)?twitter.com\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"
            type="text"
          />
          <small *ngIf="twitterurl.errors?.['pattern']" class="p-error">X URL is invalid</small>
        </div>

        <!-- Row 6- instagramURL:4, untappd:4, BLANK -->
        <!-- instagramURL -->
        <div class="field col-12 md:col-4 md:col-4">
          <label for="instagramurl">Instagram URL</label>
          <input
            #instagramurl="ngModel"
            (ngModelChange)="onChangeInstagramURL($event)"
            [(ngModel)]="currentPlace.instagramURL"
            [placeholder]="instagramUrlPlaceholder"
            class="inputfield w-full form-control"
            id="instagramurl"
            name="instagramurl"
            pInputText
            pattern="http(s)?:\/\/(www\.)?instagram.com\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"
            type="text"
          />
          <small *ngIf="instagramurl.errors?.['pattern']" class="p-error">Instagram URL is invalid</small>
        </div>

        <!-- untappd -->
        <div class="field col-12 md:col-4 md:col-4">
          <label for="untappdurl">Untappd URL</label>
          <input
            #untappdurl="ngModel"
            (ngModelChange)="onChangeUntappd($event)"
            [(ngModel)]="currentPlace.untappd"
            [placeholder]="untappdUrlPlaceholder"
            class="inputfield w-full form-control"
            id="untappdurl"
            name="untappdurl"
            pInputText
            pattern="http(s)?:\/\/(www\.)?untappd.com\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)"
            type="text"
          />
          <small *ngIf="untappdurl.errors?.['pattern']" class="p-error">Untappd URL is invalid</small>
        </div>

        <!-- logoUrl -->
        <div class="field col-12 md:col-4 md:col-4">
          <label for="logourl">Logo URL</label>
          <input
            #logourl="ngModel"
            (ngModelChange)="onChangeLogoUrl($event)"
            [(ngModel)]="currentPlace.logoUrl"
            [placeholder]="logoUrlPlaceholder"
            class="inputfield w-full form-control"
            id="logourl"
            name="logourl"
            pInputText
            pattern="logo\/logo[0-9]{4}([_][0-9]{2})?\.png"
            type="text"
          />
          <small *ngIf="logourl.errors?.['pattern']" class="p-error">Logo URL is invalid</small>
        </div>
      </div>
    </form>
  </div>

  <ng-template pTemplate="footer">
    <div class="flex justify-content-between flex-wrap">
      <div class="flex align-items-left justify-content-center text-sm">* Required Field</div>
      <div class="flex align-items-right justify-content-center">
        <p-button
          (onClick)="handleCancel()"
          icon="pi pi-times"
          label="Cancel"
          styleClass="p-button-outlined"
        ></p-button>
        <p-button
          (onClick)="placeForm.ngSubmit.emit()"
          [disabled]="
            !isDirty ||
            !placeForm.form.dirty ||
            placeForm.form.pristine ||
            !placeForm.form.valid
          "
          icon="pi pi-check"
          label="Submit"
        ></p-button>
      </div>
    </div>
  </ng-template>
</p-dialog>

/*
 * Copyright © MEFTEK LLC. All rights reserved.
 * This information is confidential and proprietary to MEFTEK LLC
 * and may not be used, modified, copied or distributed.
 */

import {EnvTypeEnum, envTypeIsStage} from './env-type.enum';

export interface Place {
  // Required
  address: string;
  id: number;
  latitude: number | undefined;
  longitude: number | undefined;
  name: string;
  sequence: number;

  // Optional
  charm?: string; // A/R - Active/Retired
  charmStatus?: string; // A/R - Active/Retired
  charmDesc?: string;
  cheer?: string; // Y/N - Yes/No
  cheerStatus?: string; // A/S/R/H - Active, Soon, Retired, Hidden
  city?: string;
  dog?: string; // Y/N - Yes/No
  facebookURL?: string;
  instagramURL?: string;
  logoData?: string;
  logoUrl?: string;
  phone?: string;
  recycle?: string; // Y/N - Yes/No
  rqc?: string; // Q/C - Quit/Closed
  state?: string;
  status?: string; // A/S/R/H - Active, Soon, Retired, Hidden
  tagStatus?: string; // A/S/R/H - Active, Soon, Retired, Hidden
  twitterURL?: string;
  untappd?: string;
  websiteURL?: string;
  createdDate?: string;
  createdDateValue?: Date;
  createdBy?: string;
  updatedDate?: string;
  updatedDateValue?: Date;
  updatedBy?: string;

  // Form only
  coordinates?: string; // longitude,latitude
  dirty?: boolean; // T/F - True if data is dirty
  staged?: boolean;
  street?: string;
  zip?: string;

  // Firebase only
  key?: string | null; // Firebase key value
}

export const statuses = {
  no: 'N',
  yes: 'Y',
  active: 'A',
  outOfStock: 'O',
  soon: 'S',
  tempClosed: 'T',
  hidden: 'H',
  retired: 'R',
  allActive: 'AOT',
  allActiveSoon: 'AOST',
  hiddenRetired: 'HR',
}

export const initialPlaceValue = {
  id: 0,
  sequence: 0,
  name: '',
  address: '',
  city: '',
  coordinates: '',
  state: '',
  latitude: undefined,
  longitude: undefined,
} as Place;

/**
 * Returns true if the value is yes
 * @param value to test
 */
function isYes(value?: string): boolean {
  return isNotBlank(value) && value === 'Y';
}

/**
 * Returns true if the value is blank
 * @param value to test
 */
function isBlank(value?: string): boolean {
  return value == null || value?.length === 0;
}

/**
 * Returns true if the value is not blank
 * @param value to test
 */
function isNotBlank(value?: string): boolean {
  return value != null && value?.length > 0;
}

/**
 * Returns true if the value is blank or no
 * @param value to test
 */
function isBlankOrNo(value?: string): boolean {
  return value == null || value?.length === 0 || value?.toLowerCase() === 'n';
}

/**
 * Returns true if the value is not blank and is active or soon
 * @param value to test
 */
function isNotBlankAndActiveOrSoon(value?: string): boolean {
  return (value != null && value?.length > 0) && (value === 'A'|| value === 'S');
}

export function setStatus(tagStatus?: string, cheerStatus?: string): string | undefined {
  let newStatus: string | undefined;

  if (tagStatus != null) {
    // tagStatus = AHORST
    if (statuses.allActive.includes(tagStatus)) {
      // tagStatus = AOT
      newStatus = statuses.active;
    } else {
      newStatus = tagStatus;
    }
  } else {
    // tagStatus = null | undefined
    if (cheerStatus != null) {
      // cheerStatus = AHORST
      if (statuses.allActive.includes(cheerStatus)) {
        // cheerStatus = AOT
       newStatus = statuses.active;
      } else {
        // cheerStatus = HRS
       newStatus = cheerStatus
      }
    }
  }
  return newStatus;
}

/**
 * Set the cheer attribute based on cheerStatus
 * @param cheerStatus The places cheerStatus
 */
export function setCheer(cheerStatus?: string): string | undefined{
  return statuses.allActiveSoon.includes(cheerStatus ?? '-') ? statuses.yes : undefined;
}

export function formatNumbers1(num1: number | undefined, num2: number | undefined): string {
  // Convert numbers to strings and split them by decimal point
  if (num1 != null && num2 != null) {
    const [num1IntegerPart, num1DecimalPart] = num1.toFixed(2).split('.');
    const [num2IntegerPart, num2DecimalPart] = num2.toFixed(2).split('.');

    // Format integer part with commas
    const formattedNum1Integer = num1IntegerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const formattedNum2Integer = num2IntegerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Concatenate integer and decimal parts with appropriate punctuation
    const formattedNum1 = `${formattedNum1Integer}.${num1DecimalPart}`;
    const formattedNum2 = `${formattedNum2Integer}.${num2DecimalPart}`;

    // Combine the formatted numbers with a comma and return
    return `${formattedNum1},${formattedNum2}`;
  }
  return '';
}

export function formatNumbers(num1: number | undefined, num2: number | undefined): string {
  // Convert numbers to strings and split them by decimal point
  if (num1 != null && num2 != null) {
    // Check if the number has digits to the right of the decimal
    const decimalPart1 = num1.toString().split('.')[1];
    const hasDecimalPart1 = decimalPart1 !== undefined && parseInt(decimalPart1) !== 0;
    const decimalPart2 = num2.toString().split('.')[1];
    const hasDecimalPart2 = decimalPart2 !== undefined && parseInt(decimalPart2) !== 0;

    // Check if the number has less than 3 digits to the right of the decimal
    const hasLessThanThreeDigits1 = !hasDecimalPart1 || decimalPart1?.length < 3;
    const hasLessThanThreeDigits2 = !hasDecimalPart2 || decimalPart2?.length < 3;

    // Format the number with appropriate decimals
    const formattedNum1 = hasLessThanThreeDigits1 ? num1.toFixed(2) : num1.toString();
    const formattedNum2 = hasLessThanThreeDigits2 ? num2.toFixed(2) : num2.toString();

    // Combine the formatted numbers with a comma and return
    return `${formattedNum1},${formattedNum2}`;
  }
  return '';
}

/**
 * Expand place by only initializing attributes that are null or undefined
 * @param place to expand
 */
export function expandPlace(place: Place): Place {
  return {
    ...place,
    charm: place.charm,
    charmStatus: place.charmStatus,
    charmDesc: place.charmDesc ? place.charmDesc : '',
    cheer: place.cheer ? place.cheer : 'N',
    cheerStatus: place.cheerStatus,
    coordinates: formatNumbers(place.latitude, place.longitude),
    dog: place.dog ? place.dog : 'N',
    facebookURL: place.facebookURL ? place.facebookURL : '',
    instagramURL: place.instagramURL ? place.instagramURL : '',
    logoUrl: place.logoUrl ? place.logoUrl: '',
    phone: place.phone ? place.phone : '',
    recycle: place.recycle ? place.recycle : 'N',
    rqc: place.rqc ? place.rqc : '',
    status: place.status,
    tagStatus: place.tagStatus ,
    twitterURL: place.twitterURL ? place.twitterURL : '',
    untappd: place.untappd ? place.untappd : '',
    websiteURL: place.websiteURL ? place.websiteURL : '',
    createdBy: place.createdBy ? place.createdBy : 'System',
    createdDate: place.createdDate ? place.createdDate : '2024-04-30 00:00:00',
    createdDateValue: place.createdDate ? new Date(place.createdDate) : new Date('2024-04-30 00:00:00'),
    updatedBy: place.updatedBy ? place.updatedBy : 'System',
    updatedDate: place.updatedDate ? place.updatedDate : '2024-04-30 00:00:00',
    updatedDateValue: place.updatedDate ? new Date(place.updatedDate) : new Date('2024-04-30 00:00:00'),
  } as Place;
}

/**
 * Expand places by only adding attributes that are null or undefined
 * @param places place array to expand
 */
export function expandPlaces(places: Place[]): Place[] {
  return places.map((place) => {
    return expandPlace(place);
  });
}

/**
 * Prepare place by adding attributes that are not null, undefined or blank
 * @param place to prepare
 * @param envType STAGE or PROD (stage/places or data/places)
 * @param removeDates true if you want the create and update date values set to undefined
 */
export function preparePlace(place: Place, envType: EnvTypeEnum, removeDates: boolean = false): Place {
  let preparedPlace: Place = {
    ...place,
    charm: isNotBlank(place.charmStatus) ? place.charmStatus : place.charm,
    charmStatus: isNotBlank(place.charmStatus) ? place.charmStatus : place.charm,
    charmDesc: isNotBlank(place.charmDesc) ? place.charmDesc : undefined,
    cheer: setCheer(place.cheerStatus), // isNotBlankAndActiveOrSoon(place.cheerStatus) ? 'Y' : place.cheer,
    cheerStatus: isNotBlank(place.cheerStatus) ? place.cheerStatus : undefined, // (place.cheer === 'Y' ? 'Y' : undefined),
    dog: isYes(place.dog) ? place.dog : undefined,
    facebookURL: isNotBlank(place.facebookURL) ? place.facebookURL : undefined,
    instagramURL: isNotBlank(place.instagramURL) ? place.instagramURL : undefined,
    logoUrl: isNotBlank(place.logoUrl) ? place.logoUrl : undefined,
    recycle: isYes(place.recycle) ? place.recycle : undefined,
    rqc: isNotBlank(place.rqc) ? place.rqc : undefined,
    staged: envTypeIsStage(envType) ? (!removeDates ? place.dirty : place.staged) : undefined,
    status: setStatus(place.tagStatus, place.cheerStatus), // isNotBlank(place.tagStatus) ? place.tagStatus : place.status,
    tagStatus: isNotBlank(place.tagStatus) ? place.tagStatus : undefined, // place.status,
    twitterURL: isNotBlank(place.twitterURL) ? place.twitterURL : undefined,
    untappd: isNotBlank(place.untappd) ? place.untappd : undefined,
    websiteURL: isNotBlank(place.websiteURL) ? place.websiteURL : undefined,
    createdBy: isNotBlank(place.createdBy) ? place.createdBy : undefined,
    createdDate: place.createdDate != null ? place.createdDate : undefined,
    // createdDateValue: !removeDates ? (place.createdDate ? new Date(place.createdDate) : undefined) : place.createdDateValue,
    updatedBy: isNotBlank(place.updatedBy) ? place.updatedBy : undefined,
    updatedDate: place.updatedDate != null ? place.updatedDate : undefined,
    // updatedDateValue: !removeDates ? (place.updatedDate ? new Date(place.updatedDate) : undefined) : place.updatedDateValue,
  };
  return removeUndefinedOrNull(preparedPlace, envType);
}

/**
 * This method updates the Place object to the new DB Version
 * @param place to update
 * @param envType STAGE or PROD (stage/places or data/places) */
export function updatePlaceDbVersion(place: Place, envType: EnvTypeEnum): Place {
  const updatedPlace = {
    ...place,
    charmStatus: place.charm ?? undefined,
    cheerStatus: place.cheer === 'Y' ? 'Y' : undefined,
    tagStatus: place.status
  }
  return removeUndefinedOrNull(updatedPlace, envType);
}

/**
 * Removes all undefined, null or unnecessary attributes from Place
 * @param place to reduce
 * @param envType STAGE or PROD (stage/places or data/places)
 */
export function removeUndefinedOrNull(place: Place, envType: EnvTypeEnum): Place {
  const reducedPlace = { ...place };
  removeUndefinedNullAndBlank(reducedPlace);

  // // Remove all optional data if not set/defined
  // if (isBlank(reducedPlace.charm)) delete reducedPlace.charm;
  // if (isBlank(reducedPlace.charmDesc)) delete reducedPlace.charmDesc;
  // if (isBlank(reducedPlace.charmStatus)) delete reducedPlace.charmStatus;
  // if (isBlank(reducedPlace.cheerStatus)) delete reducedPlace.cheerStatus;
  // if (isBlank(reducedPlace.city)) delete reducedPlace.city;
  // if (isBlank(reducedPlace.createdBy)) delete reducedPlace.createdBy;
  // if (isBlank(reducedPlace.createdDate)) delete reducedPlace.createdDate;
  // if (isBlank(reducedPlace.createdDate)) delete reducedPlace.createdDateValue;
  // if (isBlank(reducedPlace.facebookURL)) delete reducedPlace.facebookURL;
  // if (isBlank(reducedPlace.instagramURL)) delete reducedPlace.instagramURL;
  // if (isBlank(reducedPlace.logoUrl)) delete reducedPlace.logoUrl;
  // if (isBlank(reducedPlace.phone)) delete reducedPlace.phone;
  // if (isBlank(reducedPlace.rqc)) delete reducedPlace.rqc;
  // if (isBlank(reducedPlace.state)) delete reducedPlace.state;
  // if (isBlank(reducedPlace.status)) delete reducedPlace.status;
  // if (isBlank(reducedPlace.tagStatus)) delete reducedPlace.tagStatus;
  // if (isBlank(reducedPlace.twitterURL)) delete reducedPlace.twitterURL;
  // if (isBlank(reducedPlace.updatedBy)) delete reducedPlace.updatedBy;
  // if (isBlank(reducedPlace.updatedDate)) delete reducedPlace.updatedDate;
  // if (isBlank(reducedPlace.updatedDate)) delete reducedPlace.updatedDateValue;
  // if (isBlank(reducedPlace.untappd)) delete reducedPlace.untappd;
  // if (isBlank(reducedPlace.websiteURL)) delete reducedPlace.websiteURL;

  // Remove attributes we don't need/want in database
  delete reducedPlace.coordinates;
  delete reducedPlace.dirty;
  delete reducedPlace.street;
  delete reducedPlace.zip;
  delete reducedPlace.createdDateValue;
  delete reducedPlace.updatedDateValue;

  if (isBlankOrNo(reducedPlace.cheer)) delete reducedPlace.cheer;
  if (isBlankOrNo(reducedPlace.dog)) delete reducedPlace.dog;
  if (isBlankOrNo(reducedPlace.recycle)) delete reducedPlace.recycle;

  // if (reducedPlace.staged == null) delete reducedPlace.staged;

  return reducedPlace;
}

export function removeUndefinedNullAndBlank(obj: any): any {
  // Iterate over each property in the object
  for (const key in obj) {
    // Check if the property is a direct property of the object
    if (obj.hasOwnProperty(key)) {
      // If the property is undefined, null, or blank, delete it
      if (obj[key] == null || obj[key] === '') {
        delete obj[key];
      }
    }
  }
  return obj;
}
